  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,500&display=swap');

*{
  padding: 0;
  margin: 0;
}
body{
  padding: 0;
  margin: auto;
  font-family: "Poppins", sans-serif;
}
.container{
  margin: 0px 120px;
  padding: 20px;
}
.float-right{
  float: right;
}
.d_flex{
  display: flex;
  justify-content: space-between;
}
.r_card{
  display: flex;
  flex-direction: row;
  padding: 10px;
}
.c_card{
padding: 10px;
}
.text-center{
  text-align: center;
}

 h1{
  font-size: 64px;
  font-weight: 800;
  line-height: 75px;
  color: rgb(0, 0, 0);
}

button{
border: none;
border-radius: 10px;
font-size: 14px;
font-family: "Poppins", sans-serif;
font-weight: 600;
cursor: pointer;
}
.btn-primary{
  max-width: 500px;
min-width: 100px;
padding: 10px;
height: 45px;
background-color: rgb(22, 22, 214);
color: #fff;
}
.btn-primary:hover{
background-color: blue;
color: #fff;
}
.btn-secondary{
  max-width: 500px;
min-width: 100px;
padding: 10px;
  height: 45px;
  background-color: rgb(226, 237, 247);
  color: rgb(22, 22, 214);
  }
  .btn-secondary:hover{
  background-color: rgb(239, 246, 252);
  }

  .btn-danger{
    max-width: 500px;
min-width: 100px;
padding: 10px;
    height: 45px;
    background-color: rgb(238, 29, 22);
    color: #fff;
    }
    .btn-danger:hover{
    background-color: rgb(247, 53, 46);
    color: #fff;
   }

   .btn-warning{
    max-width: 500px;
    min-width: 100px;
    padding: 10px;
    height: 45px;
    background-color: rgb(214, 183, 47);
    color: #fff;
    }
    .btn-warning:hover{
    background-color:rgb(235, 204, 68);
    color: #fff;
   }
   .btn-success-large{
    width: 250px;
    height: 70px;
    border-radius: 50px;
    background-color: rgb(21, 170, 58);
    color: #fff;
    box-shadow: rgba(59, 216, 88, 0.2) 10px 0px 20px 5px;
}
.btn-success-large:hover{
  background-color: rgb(43, 206, 84);
  color: #fff;
 }
   .btn-success{
    max-width: 500px;
    min-width: 100px;
    padding: 10px;
    height: 45px;
    background-color: rgb(21, 170, 58);
    color: #fff;
    }
    .btn-success:hover{
    background-color: rgb(43, 206, 84);
    color: #fff;
   }
   .btn-success-out{
    max-width: 500px;
    min-width: 100px;
    padding: 10px;
    height: 45px;
    color: rgb(21, 170, 58);
    background-color: rgb(232, 250, 237);
    }
    .btn-success-out:hover{
    background-color: rgb(43, 206, 84);
    color: #fff;
   }
   /*-------input design -----*/

   input{
     width: 80%;
     height: 50px;
     border-radius: 4px;
     border: 1px solid #efefef;
     margin-bottom: 10px;
     padding: 0px 20px 0 20px;
     font-size: 16px;
   }
   select{
    width: 92%;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #efefef;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 0px 20px 0 20px;
    font-size: 16px;
  }

  
   textarea{
    width: 80%;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #efefef;
    margin-bottom: 10px;
    padding: 0px 20px 0 20px;
    font-size: 16px;
  }


 
   /*---- input design ends here ---*/
   a{
     color: rgb(43, 206, 84);
   }

ul{
 list-style-type: none;
}
a{
  text-decoration: none;
}


.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  font-size: 1rem;
}

.pagination .page-num {
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 20px;
  font-weight: normal;
  color: rgb(14, 109, 22);
  border: 1px solid rgb(14, 199, 29);
  margin: 2px;
}

.pagination .page-num:hover {
  color: #fff;
  background-color:rgb(14, 199, 29);
}
.activePage {
  color: #fff;
  background-color: rgb(14, 199, 29);
  height: 100%;
}

   @media(max-width: 767px) {

    .r_card{
      display: flex;
      flex-direction: column;
      padding: 5px;
    }
    .container{
      margin: 0px 10px;
      padding: 5px;
    }
    .sidebar{
      display: none;
    }
    .contain{
      margin-left: 0px!important;
  
  }
   }


@media (max-width: 480px) {
  h1{
      font-size: 40px;
      font-weight: 800;
      line-height: 50px;
  }
  img{
   display: none;
  }

}