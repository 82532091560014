  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;1,500&display=swap');

.home{
    background-color: rgb(191, 247, 219);
}
 
.intro{
    padding: 200px 20px 100px 20px;
}
 .invest{
    background-color: rgb(3, 54, 29);
}
.invest .c_card{
    width: 100%;
    border: none;
}

.invest .c_card span{
    width: 80px;
    padding: 10px;
}
.intro button{
    margin-top: 20px;
}
.invest h2{
    color: #fff;
}
.invest p{
    color: #fff;
}
.market{
    padding-top: 60px;
}
.market .info span{
    background-color: rgb(253, 253, 30);
    padding: 5px;
}
.market .img img{
margin-top: 30px;
width: 450px;
border-radius: 50px;
transform: rotate(5deg);
/* border: 1px solid rgb(160, 215, 160); */
box-shadow: rgba(59, 216, 88, 0.2) 10px 0px 15px 5px;
/* background-color: red; */


}

.market .steps{
    padding: 20px 10px;
}
.market .steps-icon{
    padding: 25px;
    border-radius: 50%;
    color: #fff;
    background-color: rgb(21, 170, 58);
}

.market .c_card {
    width: 700px;
}

.profit{
    padding: 20px 20px 200px 20px;
    background-image: url("../../assets/profit.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    
}
.profit .info button{
    margin-top: 30px;
}
.profit .info h1{
    font-size: 52px;
}
.profit .info-desc{
    padding: 10px 150px;
}


@media(max-width: 767px){
    .profit .info-desc{
        padding: 10px;
    }
    .profit .info h1{
        font-size: 38px;
    }
    .market .img img{
        width:320px;
    }
    .market .steps{
    padding: 0px;
    }
    .market .c_card{
        max-width: 98%;
    }
}

@media(max-width: 1200px){
    .market .img img{
        width:320px;
        
        }
}

@media(max-width: 900px){
    .r_card{
        flex-direction: column;
    }
}

