.table{
    overflow-x: auto;
    width: 100%;
}
table{
    width: 100%;
    /* border: 1px solid green; */
}
table thead {
    text-align: left;
}
table thead tr{
    background-color: rgb(247, 253, 248);
}
table th{
    padding: 10px;
}
table td{
    padding: 10px;
}
table tbody tr{
    background-color: #fff;
}
table tbody tr:hover{
    background-color: rgb(247, 253, 248);
    cursor: pointer;
}

/* this is profile section */
.profile{
    text-align: center;
    padding: 30px;
}
.profile h4{
    margin: 20px 0px;
}
.profile_footer{
    text-align: right;
}

/* this is market-payemnt section */
.payment_terms {
    padding: 20px;
    border-left: 2px solid rgb(31, 184, 56);
}

/* dashboard form group */
.dashboard_form_group{
    display: flex;
    flex-direction: row;
    padding: 10px;
}
.dashboard_col{
    width: 50%;
}
.profile-box{
    align-items: center;
    display: flex;
    flex-direction: column;
}

