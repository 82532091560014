
.footer{
    background-color: rgb(21, 170, 58);
}
.footer .c_card{
    width: 100%;
}
.footer .links{
    padding-left: 80px;
}
.footer h1{
    color: yellow;
}
.footer span{
    color: #fff;
}
.footer li{
    color: #fff;
}
.footer p{
    color: #fff;
}
.footer h2{
    color: #fff;
}
.footer h4{
    color: #fff;
    padding: 10px;
}

.footer hr{
    border: 0.5px solid #fff;
}
.footer .rights{
  height: 30px;
  padding: 50px 10px;
}


@media (max-width: 767px) {
    .footer .links{
        padding: 10px;

    }
}